import React, { createContext } from "react";
import { postEventAPI } from "../api/eventApi";
import EventModel from "../models/event";

interface EventContextInterface {
    postEvent: (event: EventModel) => void;
}

export const EventContext = createContext<EventContextInterface>({
    postEvent: (event: EventModel) => {}
});

const EventContextProvider: React.FC = (props) => {

    const postEventHandler = async (event: EventModel) => {
        await postEventAPI(event);
    };

    const eventContextValue: EventContextInterface = {
        postEvent: postEventHandler
    };

    return (
        <EventContext.Provider value={eventContextValue}>
            {props.children}
        </EventContext.Provider>
    );
};

export default EventContextProvider;
