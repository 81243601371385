import React from "react";
import TodoForm from "./TodoForm";
import TodoList from "./TodoList";
import TodoContextProvider from "../store/todo-store";
import classes from "./TodoWorkPanel.module.css";
import UsefulLinksPanel from "./UsefulLinksPanel";

const TodoWorkPanel = () => {
    return (
        <div>
            <div className={classes["work_panel_header"]}>
                <a>{"Todo Board"}</a>
            </div>
            <div>
                <TodoContextProvider>
                    <TodoForm />
                    <TodoList />
                </TodoContextProvider>
            </div>
            <div>
                <UsefulLinksPanel/>
            </div>
        </div>
    );
};

export default TodoWorkPanel;