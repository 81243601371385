import React, {useContext} from "react";
import ExternalLinkModel from "../models/link";
import classes from "./UsefulLinkDetails.module.css";
import { EventContext } from "../store/event-store";
import EventModel from "../models/event";


interface UsefulLinkDetailsProps {
    usefulLink: ExternalLinkModel;
}

const UsefulLinkDetails = ({ usefulLink }: UsefulLinkDetailsProps) => {

    const eventCtx = useContext(EventContext);
    const postEvent = eventCtx.postEvent;

    const eventHandler = () => {
        const msgType: string  = "UiInteractionEvent"
        const userId: string = "1"

        const newEvent: EventModel = {
            userUUID: userId,
            eventTime: new Date().toISOString(),
            eventCategory: "UserActivityEvent",
            messageType: msgType,
            message: {
                interactionType: "forward_external_link",
                interactionItemId: usefulLink.linkId
            }
        };

        postEvent(newEvent)
    };

    return (
        <div>
            <a onClick={eventHandler} className={classes.a_ul} href={usefulLink.linkUrl}>
                {usefulLink.linkName}
            </a>
        </div>
    );
};

export default UsefulLinkDetails;