import EventModel from "../models/event";


export const postEventAPI = async (event: EventModel) => {
  try {
    const response = await fetch(
      "http://localhost:3001/events",
      {
        method: "POST",
        body: JSON.stringify({ ...event }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Sending Event Fail");
    }
  } catch (e) {
    throw new Error("Sending Event Fail");
  }
};