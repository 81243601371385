export interface VisitedLink {
    linkId: string;
    visits: number;
}

export interface ExternalLinkModel {
    linkId: string;
    linkName: string;
    linkUrl: string;
}

export let linkList: ExternalLinkModel[] = [
    {linkId: "401", linkName: "Scala", linkUrl: "https://docs.scala-lang.org"},
    {linkId: "402", linkName: "Apache Spark", linkUrl: "https://spark.apache.org/docs/latest"},
    {linkId: "410", linkName: "AWS Lambda", linkUrl: "https://aws.amazon.com/lambda"},
    {linkId: "404", linkName: "AWS CloudFormation", linkUrl: "https://aws.amazon.com/cloudformation"},
    {linkId: "403", linkName: "Amazon CloudFront", linkUrl: "https://aws.amazon.com/cloudfront"},
    {linkId: "405", linkName: "Amazon API Gateway", linkUrl: "https://aws.amazon.com/api-gateway"},
    {linkId: "406", linkName: "Amazon SNS", linkUrl: "https://aws.amazon.com/sns"},
    {linkId: "407", linkName: "Amazon SQS", linkUrl: "https://aws.amazon.com/sqs"},
    {linkId: "408", linkName: "Amazon EMR", linkUrl: "https://aws.amazon.com/emr"},
    {linkId: "409", linkName: "Amazon S3", linkUrl: "https://aws.amazon.com/s3"},
    {linkId: "411", linkName: "Amazon Kinesis", linkUrl: "https://aws.amazon.com/kinesis"},
    {linkId: "412", linkName: "Amazon DynamoDB", linkUrl: "https://aws.amazon.com/dynamodb"}
];

export default ExternalLinkModel;