import TodoModel from "../models/todo";

export const addTodosAPI = async (todo: TodoModel) => {
  try {
    const response = await fetch(
      "http://localhost:3001/todos",
      {
        method: "POST",
        body: JSON.stringify({ ...todo }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Sending Todo Fail");
    }

    return await response.json();
  } catch (e) {
    throw new Error("Sending Todo Fail");
  }
};

export const removeTodoAPI = async (id: string) => {
  try {
    const response = await fetch(
      `http://localhost:3001/todos/${id}`,
      {
        method: "DELETE",
      }
    );
    if (!response.ok) {
      throw new Error("Delete Todo Fail");
    }
  } catch (error) {
    throw new Error("Cannot Delete Todos");
  }
};

export const editTodoAPI = async (id: string, updateText: string) => {
  try {
    const response = await fetch(
      `http://localhost:3001/todos/${id}`,
      {
        method: "PATCH",
        body: JSON.stringify({ text: updateText }),
        headers: {
          "Content-Type": "application/json",
        }
      }
    );

    if (!response.ok) {
      throw new Error("Updating Todo Fail");
    }
    const data = await response.json();

    console.log(response);
  } catch (error) {
    throw new Error("Updating Todo Fail");
  }
};

export const checkTodoAPI = async (id: string, updateComplete: boolean) => {
  try {
    const response = await fetch(
      `http://localhost:3001/todos/${id}`,
      {
        method: "PATCH",
        body: JSON.stringify({ complete: updateComplete }),
        headers: {
          "Content-Type": "application/json",
        }
      }
    );

    if (!response.ok) {
      throw new Error("Updating Todo Fail");
    }
  } catch (error) {
    throw new Error("Updating Todo Fail");
  }
};

export const getTodosAPI = async () => {
  try {
    const response = await fetch(
      "http://localhost:3001/todos"
    );

    if (!response.ok) {
      throw new Error("Cannot get Todos, please check source");
    }

    const data = await response.json();

    const loadedTodos: TodoModel[] = [];

    for (const key in data) {
      loadedTodos.push({
        id: data[key].id,
        text: data[key].text,
        createdAt: data[key].createdAt,
        complete: data[key].complete,
      });
    }

    return loadedTodos;
  } catch (error) {
    throw new Error("Cannot get Todos");
  }
};
