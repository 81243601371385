import React from "react";
import classes from "./UsefulLinksPanel.module.css";
import { linkList } from "../models/link";
import UsefulLinkDetails from "./UsefulLinkDetails";


const UsefulLinksPanel = () => {

   const links = linkList.map((usefulLink) => <UsefulLinkDetails key={usefulLink.linkId} usefulLink={usefulLink}/>);

    return (
        <div>
            <div className={classes.links_header}>
               <a>Useful Links</a>
            </div>
            <div>
                <div className={classes["left_column"]}>
                    {links.splice(0, Math.ceil(links.length / 2))}
                </div>
                <div className={classes["right_column"]}>
                    {links}
                </div>
            </div>
        </div>
    );
};

export default UsefulLinksPanel;