import React from "react";
import "./App.css";
import TodoWorkPanel from "./components/TodoWorkPanel";
import EventContextProvider from "./store/event-store";


function App() {
  return (
    <>
      <main className="App">
          <EventContextProvider>
            <TodoWorkPanel />
          </EventContextProvider>
      </main>
    </>
  );
}

export default App;
