import React, { useContext } from "react";
import classes from "./TaskFilters.module.css";
import { TodoContext } from "../store/todo-store";
import { EventContext } from "../store/event-store";
import { filter } from "../models/todo";
import EventModel from "../models/event";

const TaskFilters = () => {

    const eventCtx = useContext(EventContext);
    const postEvent = eventCtx.postEvent;
    const todoCtx = useContext(TodoContext);
    const changeFilter = todoCtx.changeFilter;
    const filterOrder = todoCtx.filter;
    console.log(todoCtx);

    const eventHandler = (fl: filter) => {
        const msgType: string  = "UiInteractionEvent"
        const userId: string = "1"

        const getFilterId = () => {
            switch (fl) {
                case filter.all:
                    return "301";
                case filter.active:
                    return "302";
                case filter.completed:
                    return "303";
            }
        }

        const newEvent: EventModel = {
            userUUID: userId,
            eventTime: new Date().toISOString(),
            eventCategory: "UserActivityEvent",
            messageType: msgType,
            message: {
                interactionType: "filter_todos",
                interactionItemId: getFilterId()
            }
        };

        postEvent(newEvent)
    };

    const changeFilterOrder = (filter: filter) => {
        eventHandler(filter)
        changeFilter(filter);
    };

    return (
        <ul className={classes["task-filters"]}>
            <li onClick={changeFilterOrder.bind(null, filter.all)}>
                <a className={filterOrder === filter.all ? classes.active : ""} href="#">
                    View All
                </a>
            </li>
            <li onClick={changeFilterOrder.bind(null, filter.active)}>
                <a className={filterOrder === filter.active ? classes.active : ""} href="#">
                    Active
                </a>
            </li>
            <li onClick={changeFilterOrder.bind(null, filter.completed)}>
                <a className={filterOrder === filter.completed ? classes.active : ""} href="#">
                    Completed
                </a>
            </li>
        </ul>
    );
};

export default TaskFilters;
